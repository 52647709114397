import EmptyUtil from "@/utils/common/EmptyUtil";

export default class WorkOrder {

    ///服务商ID，就是线路ID
    amountId;

    ///申诉附件图片，逗号分割的图片url
    appealAttachment;

    ///申诉内容
    appealContent;

    ///审核通过，0-不通过 1-通过
    approval;

    ///被叫
    callee;

    ///主叫
    caller;

    ///通话ID
    callId;
    //通话时间
    callTime;

    ///质检记录ID
    checkId;

    ///创建人，一般是客服创建
    createBy;

    ///创建时间
    createTime;

    ///不通过原因
    disapprovalReason;

    ///执行处罚方式，0-手动 1-系统自动
    execType;

    ///工单ID
    id;

    ///通知内容
    noticeDesc;

    ///通知时间
    noticeTime;

    ///通知方式，0-手动 1-系统自动
    noticeType;

    ///运营商处罚内容
    oprPenalties;

    ///来源
    orderFrom;

    ///工单号
    orderNo;

    ///处罚类型，管理员二次修改
    punishConfType;

    ///处罚量，管理员二次修改
    punishConfVal;

    ///最终处罚类型，管理员最终修改
    punishFinalType;

    ///最终处罚量，管理员最终修改
    punishFinalVal;

    ///建议处罚类型，客服建议
    punishPropType;

    ///建议处罚量，客服建议
    punishPropVal;

    ///报备内容
    reportDesc;

    ///工单状态，3-待报备 6-待审批 9-待通知 12-待申诉 15-申诉待审批 17-已作废 18-已完结
    status;

    ///渠道用户ID
    uid;

    ///更新时间
    updateTime;

    ///违规说明
    violDesc;

}
export const APPEAL_EXPIRED_DURATION = 12 * 60 * 60 * 1000;//申诉过期时长

export const WORK_ORDER_STATUS_LIST = [
    {
        value: 3,
        label: '待报备',
    },
    {
        value: 6,
        label: '待审批',
    },
    {
        value: 9,
        label: '待通知',
    },
    {
        value: 12,
        label: '待申诉',
    },
    {
        value: 15,
        label: '申诉待审批',
    },
    {
        value: 17,
        label: '已作废',
    },
    {
        value: 18,
        label: '已完结',
    },
]

export function statusDesc(status) {
    //工单状态，3-待报备 6-待审批 9-待通知 12-待申诉 15-申诉待审批 17-已作废 18-已完结
    let res = '';
    res = WORK_ORDER_STATUS_LIST.find(item => item.value == status)?.label || '';
    return res;
}

export function orderFromDesc(orderFrom) {
    //来源 0-平台质检 1-运营商质检
    let res = '';
    if (orderFrom == 0) {
        res = '平台质检';
    } else if (orderFrom == 1) {
        res = '运营商质检';
    }
    return res;
}

export function punishTypeDesc(punishType) {
    let res = '';
    if (punishType == 0) {
        res = '提醒';
    } else if (punishType == 1) {
        res = '保证金';
    } else if (punishType == 2) {
        res = '罚款';
    } else if (punishType == 3) {
        res = '关停主叫';
    } else if (punishType == 4) {
        res = '提醒/保金证';
    } else if (punishType == 5) {
        res = '禁用主叫/罚款';
    }
    return res;
}

export const MAX_FORBIDDEN_HOUR = 867240;//99年

//处罚类型 0-提醒 1-保证金（金额） 2-罚款（金额） 3-关停主叫 4-提醒/保金证（金额） 5-禁用主叫/罚款（金额）
export const PUNISH_TYPE_LIST = [
    {
        value: 0,
        label: '提醒',
    },
    {
        value: 1,
        label: '保证金',
    },
    {
        value: 2,
        label: '罚款',
    },
    {
        value: 3,
        label: '关停主叫',
    },
    {
        value: 4,
        label: '提醒/保金证',
    },
    {
        value: 5,
        label: '禁用主叫/罚款',
    },
]
export const PUNISH_AMOUNT_LIST = [
    {
        value: 100,
        label: '100元',
    },
    {
        value: 200,
        label: '200元',
    },
    {
        value: 300,
        label: '300元',
    },
    {
        value: 400,
        label: '400元',
    },
    {
        value: 500,
        label: '500元',
    },
    {
        value: 600,
        label: '600元',
    },
    {
        value: 700,
        label: '700元',
    },
    {
        value: 800,
        label: '800元',
    },
    {
        value: 900,
        label: '900元',
    },
    {
        value: 1000,
        label: '1000元',
    },
    {
        value: 2000,
        label: '2000元',
    },
    {
        value: 3000,
        label: '3000元',
    },
    {
        value: 4000,
        label: '4000元',
    },
    {
        value: 5000,
        label: '5000元',
    },
    {
        value: 10000,
        label: '10000元',
    },
    {
        value: 20000,
        label: '20000元',
    },
    {
        value: 100000,
        label: '100000元',
    },
]
// export const PUNISH_DURATION_LIST = [
//     {
//         value: 12,
//         label: '12小时',
//     },
//     {
//         value: 24,
//         label: '1天',
//     },
//     {
//         value: 24 * 7,
//         label: '7天',
//     },
//     {
//         value: MAX_FORBIDDEN_HOUR,
//         label: '永久',
//     },
// ]
export const PUNISH_DURATION_LIST = [
    {label: '4小时', value: 4,},
    {label: '8小时', value: 8,},
    {label: '12小时', value: 12,},
    {label: '24小时', value: 24,},
    {label: '48小时', value: 48,},
    {label: '永久', value: MAX_FORBIDDEN_HOUR,},
]

export function punishValueDesc(punishType, punishValue) {
    let res = '';
    if (punishType == 0) {
        res = '';
    } else if (punishType == 3) {
        res = PUNISH_DURATION_LIST.find(item => item.value == punishValue)?.label || '';
        if (EmptyUtil.isEmpty(res)) {
            if (punishValue >= 24) {
                res = (punishValue / 24) + '天';
            } else {
                res = punishValue + '小时';
            }
        }
    } else if (punishType == 1 || punishType == 2 || punishType == 4 || punishType == 5) {
        res = PUNISH_AMOUNT_LIST.find(item => item.value == punishValue)?.label || '';
    }
    return res;
}
