<template>
  <a-form ref="searchFormRef" layout="horizontal" :model="searchForm"
          :label-col="labelCol" :wrapper-col="wrapperCol"
          class="m3-width-full m3-relative">
    <!--    &nbsp;字符：不断行的空白格，该空格占据的宽度受字体影响。-->
    <!--    &ensp;字符：相当全角状态键入半个“空格”键（半个汉字的宽度）。-->
    <!--    &emsp;字符：相当全角状态键入“空格”键（1个汉字的宽度）。-->
    <a-row gutter="20">
      <a-col v-if="isAdmin" span="5">
        <a-form-item label="用&emsp;&emsp;户">
          <a-select v-model:value="searchForm.uid" :options="userList" class="m3-width-60" showSearch
                    :filterOption="(input, option)=> { return option.label.indexOf(input) >= 0;}"></a-select>
        </a-form-item>
      </a-col>
      <a-col span="5">
        <a-form-item label="服&ensp;务&ensp;商">
          <a-select v-model:value="searchForm.amountId" :options="amountList" class="m3-width-60" showSearch
                    :filterOption="(input, option)=> { return option.label.indexOf(input) >= 0;}"></a-select>
        </a-form-item>
      </a-col>
      <a-col span="5">
        <a-form-item label="惩罚类型">
          <a-select v-model:value="searchForm.punishFinalType" :options="punishTypeList" class="m3-width-60"></a-select>
        </a-form-item>
      </a-col>
      <a-col span="5">
        <a-form-item label="工单状态">
          <a-select v-model:value="searchForm.status" :options="statusList" class="m3-width-60"></a-select>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row gutter="20">
      <a-col span="10">
        <a-form-item label="时间筛选">
          <el-date-picker v-model="searchDateTime"
                          :style="{width:'80%'}"
                          type="datetimerange" value-format="YYYY-MM-DD HH:mm:ss"
                          :default-time="[new Date(2000, 1, 1, 0, 0, 0),new Date(2000, 2, 1, 23, 59, 59),]"
                          :shortcuts="dateShortcuts"
                          range-separator="-" start-placeholder="开始时间" end-placeholder="结束时间"/>
          <!--            <a-range-picker-->
          <!--                :style="{width:'60%'}"-->
          <!--                :locale="locale"-->
          <!--                v-model:value="searchForm.timeArr"-->
          <!--                :show-time="{ format: 'HH:mm' }"-->
          <!--                format="YYYY-MM-DD HH:mm"-->
          <!--                :placeholder="['开始时间', '结束时间']"/>-->
        </a-form-item>
      </a-col>
      <a-col span="5">
        <a-form-item label="来&emsp;&emsp;源">
          <a-select v-model:value="searchForm.orderFrom" :options="orderFromList" class="m3-width-60"></a-select>
        </a-form-item>
      </a-col>
      <a-col v-if="isAdmin" span="5">
        <a-form-item label="是否通过">
          <a-select v-model:value="searchForm.approval" :options="approvalList" class="m3-width-60"></a-select>
        </a-form-item>
      </a-col>
    </a-row>

    <a-row v-if="showMoreSearch" gutter="20">
      <a-col span="5">
        <a-form-item label="主&emsp;&emsp;叫">
          <div class="m3-flex">
            <a-input v-model:value="searchForm.caller" placeholder="主叫号码" class="m3-width-60"/>
          </div>
        </a-form-item>
      </a-col>
      <a-col span="5">
        <a-form-item label="被&emsp;&emsp;叫">
          <div class="m3-flex">
            <a-input v-model:value="searchForm.callee" placeholder="被叫号码" class="m3-width-60"/>
          </div>
        </a-form-item>
      </a-col>
      <a-col span="5">
        <a-form-item label="工&ensp;单&ensp;号">
          <div class="m3-flex">
            <a-input v-model:value="searchForm.orderNo" placeholder="工单号" class="m3-width-60"/>
          </div>
        </a-form-item>
      </a-col>
      <!--      <a-col span="5">-->
      <!--        <a-form-item label="通&ensp;话&ensp;ID">-->
      <!--          <div class="m3-flex">-->
      <!--            <a-input v-model:value="searchForm.callId" placeholder="通话ID" class="m3-width-60"/>-->
      <!--          </div>-->
      <!--        </a-form-item>-->
      <!--      </a-col>-->
    </a-row>

    <div class="m3-flex" :style="{position: 'absolute',bottom: '24px',right: '20px'}">
      <a-button v-if="isAdmin" :type="'primary'" class="m3-margin-right-s" @click="addWorkOrder">
        新建
      </a-button>
      <a-button :type="'primary'" @click="toSearch">
        查询
      </a-button>
      <a-button @click="resetSearch" class="m3-margin-left-s">
        重置
      </a-button>
      <a-button class="m3-margin-left-s" type="link" @click="showMoreSearch=!showMoreSearch">
        {{ showMoreSearch ? '收起' : '展开' }}
        <UpOutlined v-if="showMoreSearch"/>
        <DownOutlined v-else/>
      </a-button>
    </div>

  </a-form>
</template>

<script>
import AButton from "ant-design-vue/lib/button/button";
import {ASelect} from "ant-design-vue/lib/select";
import AInput from "ant-design-vue/lib/input/Input";
import {DownOutlined, UpOutlined} from "@ant-design/icons-vue";
import ACol from "ant-design-vue/lib/grid/Col";
import {ARow} from "ant-design-vue/lib/grid/Row";
import AFormItem from "ant-design-vue/lib/form/FormItem";
import {useStore} from "vuex";
import {getDate} from "@/utils/timeToChoose";
import {computed, onMounted, reactive, ref, toRaw, toRefs, watch} from "vue";
import EmptyUtil from "@/utils/common/EmptyUtil";
import record from "@/api/open/voiceCheck/record";
import {safePromise} from "@/utils/common/safePromise";
import _lineService from "@/api/open/LineQualityInspections";
import {Form} from "ant-design-vue";
import {PUNISH_DURATION_LIST, PUNISH_TYPE_LIST, WORK_ORDER_STATUS_LIST} from "@/views/open/workOrder/WorkOrder";

export default {
  name: "SearchForm",
  emits: ['change', 'search', 'add'],
  components: {
    AButton,
    ASelect,
    AInput,
    DownOutlined,
    UpOutlined,
    ACol,
    ARow,
    AFormItem
  },
  props: {
    amountOption: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, context) {
    let {amountOption} = toRefs(props);

    let store = useStore();
    let isAdmin = computed(() => store.getters?.userType == 'system');
    // let isAdmin = computed(() => false);

    let startDateTime = getDate(-15) + ' 00:00:00';
    let endDateTime = getDate(0) + ' 23:59:59';

    let searchForm = reactive({
      uid: '',//渠道用户ID
      amountId: '',//服务商ID 就是线路ID
      status: '',//工单状态 3-待报备 6-待审批 9-待通知 12-待申诉 15-申诉待审批 18-已完结
      punishFinalType: '',//最终惩罚类型 0-提醒 1-保证金 2-罚款 3-关停主叫 4-提醒/保金证 5-禁用主叫/罚款
      orderNo: '',//工单号
      callId: '',//通话ID
      caller: '',//主叫
      callee: '',//被叫
      orderFrom: '',//0-平台质检 1-运营商质检
      approval: '',//0-不通过 1-通过
      createTimeBegin: startDateTime,
      createTimeEnd: endDateTime,
    });

    //初始化后将参数发给列表页以初始化列表
    context.emit('change', toRaw(searchForm));

    let showMoreSearch = ref(false);
    let onMouseOver = (e) => {
      // console.log('onMouseOver', e)
      showMoreSearch.value = true;
    }
    let onMouseOut = (e) => {
      // console.log('onMouseOut', e)
      showMoreSearch.value = false;
    }

    let searchDateTime = ref([startDateTime, endDateTime]);
    const dateShortcuts = [
      {
        text: '过去一周',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
          return [start, end]
        },
      },
      {
        text: '过去一个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
          return [start, end]
        },
      },
      {
        text: '过去三个月',
        value: () => {
          const end = new Date()
          const start = new Date()
          start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
          return [start, end]
        },
      },
    ];
    watch(searchDateTime, (newV, preV) => {
      if (newV && newV.length > 1) {
        searchForm.createTimeBegin = newV[0];
        searchForm.createTimeEnd = newV[newV.length - 1];
      } else {
        searchForm.createTimeBegin = '';
        searchForm.createTimeEnd = '';
      }
    })

    //来源 0-平台质检 1-运营商质检
    let orderFromList = [
      {
        value: '',
        label: '全部',
      },
      {
        value: 0,
        label: '平台质检',
      },
      {
        value: 1,
        label: '运营商质检',
      },
    ];
    //0-不通过 1-通过
    let approvalList = [
      {
        value: '',
        label: '全部',
      },
      {
        value: 0,
        label: '不通过',
      },
      {
        value: 1,
        label: '通过',
      },
    ];

    // status:'',//工单状态 3-待报备 6-待审批 9-待通知 12-待申诉 15-申诉待审批 18-已完结
    let statusList = computed(() => {
      if (isAdmin.value) {
        let res = [
          {
            value: '',
            label: '全部',
          }
        ]
        res.splice(1, 0, ...WORK_ORDER_STATUS_LIST);
        return res;
      } else {
        return [
          {
            value: '',
            label: '全部',
          },
          {
            value: 12,
            label: '待申诉',
          },
          {
            value: 15,
            label: '申诉待审批',
          },
          {
            value: 18,
            label: '已完结',
          },
        ]
      }
    });
    //     punishFinalType:'',//最终惩罚类型 0-提醒 1-保证金 2-罚款 3-关停主叫 4-提醒/保金证 5-禁用主叫/罚款
    let punishTypeList = [
      {
        value: '',
        label: '全部',
      },
    ];
    punishTypeList.splice(1, 0, ...PUNISH_TYPE_LIST);

    let userList = ref([]);
    let getUserList = async () => {
      const res = await safePromise(_lineService._recordService.queryList())
      let systemOption = {label: '系统', value: 0};//添加一个系统项
      userList.value = res?.data?.map(item => {
        return {label: item.nickname, value: item.uid}
      }) || [];
      userList.value.splice(0, 0, systemOption);
      //添加一个全部
      userList.value.splice(0, 0, {
        value: '',
        label: '全部',
      })
    }

    let amountList = computed(() => {
      let all = {
        value: '',
        label: '全部',
      }
      let res = [];
      res.splice(0, 0, all)
      if (!EmptyUtil.isEmpty(amountOption.value)) {
        res.splice(1, 0, ...amountOption.value)
      }
      return res;
    });
    // let amountList = ref([]);
    let getAmountList = async () => {
      const res = await safePromise(_lineService._recordService.getAmountList())
      amountList.value = res?.data?.map(item => {
        return {label: item.name, value: item.id}
      }) || [];
      res.splice(0, 0, {
        value: '',
        label: '全部',
      })
    }


    //useForm 表单验证
    let useForm = Form.useForm;
    const {resetFields, validate, validateInfos, mergeValidateInfo} = useForm(searchForm, []);
    let addWorkOrder = async () => {
      context.emit('add');
    }
    let toSearch = async () => {
      context.emit('search', toRaw(searchForm));
    }
    let resetSearch = async () => {
      resetFields();
      showMoreSearch.value = false;
      await toSearch();
    }

    onMounted(() => {
      getUserList();
      // getAmountList();
    })
    return {
      labelCol: {span: 4},
      wrapperCol: {span: 14},
      isAdmin, searchForm,
      showMoreSearch, onMouseOver, onMouseOut,
      orderFromList, approvalList, statusList, punishTypeList,
      userList, amountList,
      searchDateTime, dateShortcuts,
      toSearch, resetSearch, addWorkOrder,
    }
  },
}
</script>

<style scoped>

</style>